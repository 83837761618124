<template>
  <button type="button" class="button button--square" @click="reverseOrder()">
    <img v-show="!query.sort_reverse" class="icon" src="/icons/sort-ascending.svg" />
    <img v-show="query.sort_reverse" class="icon" src="/icons/sort-descending.svg" />
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },

  methods: {
    ...mapActions({
      setSortReverse: 'query/setSortReverse',
    }),

    reverseOrder() {
      let query = { ...this.query }
      this.setSortReverse({ value: !query.sort_reverse })
    },
  },
}
</script>
