<i18n>
{
  "de": {
    "buildingsCount": "Anzahl Liegenschaften",
    "ebfSum": "Energiebezugsfläche",
    "xUnit": "Liegenschaften",
    "xUnitEbf": "m²",
    "groupByKeys": {
      "usage": "Hauptnutzung",
      "heating": "Heizungstyp",
      "feasibility_ews": "Zulässigkeit Erdwärmesonden",
      "feasibility_gw": "Zulässigkeit Grundwasser-Wärmenutzung",
      "availability_district": "Verfügbarkeit Fernwärme"
    }
  }
}
</i18n>

<template>
  <DoughnutChart
    :chart-data="processedChartData"
    :options="options"
    :grouping-name="$t(`groupByKeys.${groupByKey}`)"
    class="tag-doughnut-chart"
    exportable
  />
</template>

<script>
import _ from 'lodash'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    DoughnutChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    groupByKey: {
      type: String,
      required: true,
    },
    byArea: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    groupedValues() {
      const self = this
      return _.sortBy(
        _.values(
          this.chartData.reduce(function (res, value) {
            if (!value.tags) {
              return res
            }
            const tag = value.tags.find((t) => t.gname === self.groupByKey)
            if (tag === undefined) {
              return res
            }
            const keyVal = tag.name
            if (!res[keyVal]) {
              res[keyVal] = { key: keyVal, result: 0 }
            }
            if (self.byArea) {
              res[keyVal].result += value['energy_area']
            } else {
              res[keyVal].result += 1
            }
            return res
          }, {})
        ),
        'result'
      ).reverse()
    },

    labels() {
      if (this.groupByKey === 'heating') {
        return (
          this.groupedValues &&
          this.groupedValues.map((g) =>
            this.portfolio.getHeatingTypeName(
              (k) => this.$t(k),
              this.portfolio.heating_types.findIndex((h) => h.name === g.key)
            )
          )
        )
      }
      return this.groupedValues && this.groupedValues.map((g) => this.$t(`_buildingTags.${this.groupByKey}.${g.key}`))
    },

    colors() {
      if (this.groupByKey === 'usage') {
        return this.groupedValues && this.groupedValues.map((g) => colorPalettes.buildingUsageColors[g.key])
      } else if (this.groupByKey === 'heating') {
        return (
          this.groupedValues &&
          this.groupedValues.map((g, i) => {
            if (g.key in colorPalettes.heatingColors) {
              return colorPalettes.heatingColors[g.key]
            } else {
              return colorPalettes.energyCarrierColors[i % colorPalettes.energyCarrierColors.length]
            }
          })
        )
      } else if (this.groupByKey === 'feasibility_ews') {
        return this.groupedValues && this.groupedValues.map((g) => colorPalettes.ewsFeasibilityColors[g.key])
      } else if (this.groupByKey === 'feasibility_gw') {
        return this.groupedValues && this.groupedValues.map((g) => colorPalettes.gwFeasibilityColors[g.key])
      } else if (this.groupByKey === 'availability_district') {
        return this.groupedValues && this.groupedValues.map((g) => colorPalettes.districtAvailabilityColors[g.key])
      } else {
        return undefined
      }
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
          },
          tooltip: {
            callbacks: {
              label: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.byArea ? this.$t('xUnitEbf') : this.$t('xUnit'),
                  label: item.label,
                }),
            },
          },
        },
      }
    },

    processedChartData() {
      return {
        datasets: [
          {
            label: this.byArea ? this.$t('ebfSum') : this.$t('buildingsCount'),
            data: this.groupedValues && this.groupedValues.map((g) => g.result),
            hoverOffset: 4,
            backgroundColor: this.colors,
          },
        ],
        labels: this.labels,
      }
    },
  },
}
</script>
