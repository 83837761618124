<i18n>
{
  "de": {
    "chartsButton": "Kennzahlen",
    "countButton": "Anzahl",
    "byAreaButton": "Fläche"
  }
}
</i18n>

<template>
  <div class="c-portfolio-map-and-kpis" :class="{ 'c-portfolio-map-and-kpis--horizontal': layout === 'horizontal' }">
    <!-- Map -->
    <PortfolioMapContainer :buildings="buildings" :portfolio="portfolio" :layout="layout" />

    <!-- KPIs (Kennzahlen) -->
    <PortfolioKpis :portfolio="portfolio" :chart-data="chartData" :layout="layout" />
  </div>
</template>

<script>
import PortfolioMapContainer from '@/components/portfolio/PortfolioMapContainer.vue'
import PortfolioKpis from '@/components/portfolio/kpi/PortfolioKpis.vue'

export default {
  components: {
    PortfolioMapContainer,
    PortfolioKpis,
  },

  props: {
    portfolio: {
      type: Object,
      // required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
    layout: {
      type: String, // 'horizontal' or 'vertical'
      default: 'vertical',
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map-and-kpis {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  & > div {
    flex: 1;
    display: block;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    border-bottom: 1px solid #eee;
    overflow-y: auto;
  }

  &.c-portfolio-map-and-kpis--horizontal {
    display: none;
    min-height: initial;
    max-height: initial;
    width: calc(100% + 32px);
    height: 420px;
    margin: 0 -16px 16px;
    overflow: hidden;

    @media screen and (max-width: 1280px) {
      display: flex;
      flex-direction: row;

      & > div {
        display: block;
        width: 100%;
        height: 100%;
      }

      & > div:first-child {
        border-right: 1px solid #eee;
      }
    }
  }
}
</style>
