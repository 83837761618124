<i18n>
{
  "de": {
    "buildingsCount": "Anzahl Liegenschaften",
    "ebfSum": "Energiebezugsfläche",
    "xUnit": "Liegenschaften",
    "xUnitEbf": "m²",
    "qualityKeys": {
      "location": "Standort",
      "heating_type": "Heizungstyp",
      "heating_year": "Baujahr Heizung",
      "renovation_year": "Renovation",
      "energy_area": "EBF",
      "usage": "Nutzung"
    },
    "dataKeys": {
      "FULL": "Vollständig",
      "ESTIMATE": "Schätzung",
      "GIS_ESTIMATE": "Schätzung mit Geodaten",
      "MISSING": "Fehlend"
    }
  }
}
</i18n>

<template>
  <BarChart :chart-data="processedChartData" :options="options" exportable />
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

const dataKeys = ['FULL', 'ESTIMATE', 'GIS_ESTIMATE', 'MISSING']
const propertyKeys = ['location', 'heating_type', 'heating_year', 'renovation_year', 'energy_area', 'usage']

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BarChart,
  },

  props: {
    byArea: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    groupedValues() {
      const self = this
      let result = {}
      for (const k in dataKeys) {
        result[dataKeys[k]] = {}
        for (const j in propertyKeys) {
          result[dataKeys[k]][propertyKeys[j]] = 0
        }
      }

      return this.chartData.reduce(function (res, building) {
        Object.keys(building.data_quality).forEach((propertyKey) => {
          const qualityKey = building.data_quality[propertyKey]
          if (res[qualityKey] !== undefined && res[qualityKey][propertyKey] !== undefined) {
            if (self.byArea) {
              res[qualityKey][propertyKey] += building['energy_area']
            } else {
              res[qualityKey][propertyKey] += 1
            }
          }
        })
        return res
      }, result)
    },

    labels() {
      return propertyKeys.map((k) => this.$t(`qualityKeys.${k}`))
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            stacked: true,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) =>
                this.getTooltipLabel(item, {
                  unit: this.byArea ? this.$t('xUnitEbf') : this.$t('xUnit'),
                  label: item.label,
                }),
            },
          },
          legend: {
            position: 'bottom',
            display: true,
          },
        },
      }
    },

    processedChartData() {
      let datasets = []
      for (const c in dataKeys) {
        datasets.push({
          label: this.$t(`dataKeys.${dataKeys[c]}`),
          data: Object.values(this.groupedValues[dataKeys[c]]),
          backgroundColor: colorPalettes.dataQualityColors[dataKeys[c]],
          stack: 'default',
        })
      }
      return {
        datasets: datasets,
        labels: this.labels,
      }
    },
  },
}
</script>
