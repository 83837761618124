<i18n>
{
  "de": {
    "uploadFile": "JSON-Datei auswählen",
    "pendingMessage": "Liegenschaft wird importiert, dieser Vorgang kann einen Moment dauern.",
    "submitImport": "Bestätigen"
  }
}
</i18n>

<template>
  <Modal class="import-modal" wide @close="$emit('close', $event)">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-if="pending">
      {{ $t('pendingMessage') }}
    </div>
    <div v-else>
      {{ $t('uploadFile') }}
      <input id="portfolio-field" type="file" accept=".json" @change="onFileChanged" />
      <div v-if="building">
        <ButtonWrapper>
          <button class="button" @click="onAddBuilding">{{ $t('submitImport') }}</button>
        </ButtonWrapper>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/components/shared/Modal.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  components: {
    Modal,
    ButtonWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fileReader: null,
      building: null,
      pending: false,
      error: null,
    }
  },

  methods: {
    ...mapActions({
      addBuilding: 'building/addBuilding',
    }),

    onFileChanged(event) {
      this.error = null
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onFileRead
      this.fileReader.readAsText(file)
    },
    onFileRead() {
      const content = this.fileReader.result
      const parsedObj = JSON.parse(content)
      if (Array.isArray(parsedObj)) {
        this.error = 'TypeError: must be object, not array'
      } else if (typeof parsedObj !== 'object') {
        this.error = 'TypeError: must be object, not ' + typeof parsedObj
      } else {
        this.building = parsedObj
      }
    },

    async onAddBuilding() {
      this.pending = true
      try {
        await this.addBuilding({
          building: this.building,
          portfolioId: this.portfolio.id,
        })
        this.pending = false
        this.$emit('close')
      } catch (error) {
        this.pending = false
        this.error = error
      }
    },
  },
}
</script>
