<i18n>
{
  "de": {
    "mapButton": "Karte",
    "satelliteButton": "Satellit",
    "showMapButton": "Karte anzeigen",
    "hideMapButton": "Karte verbergen"
  }
}
</i18n>

<template>
  <div class="c-portfolio-map-container" :class="{ collapsed: isCollapsed }">
    <!-- Top buttons container -->
    <div class="buttons-container" @click="toggleCollapse">
      <!-- Map mode toggle buttons -->
      <template v-if="!isCollapsed">
        <nav class="round-buttons">
          <button type="button" :class="{ selected: mapMode === 'satellite' }" @click="setMapMode('satellite')">
            <span>{{ $t('satelliteButton') }}</span>
          </button>
          <button type="button" :class="{ selected: mapMode === 'map' }" @click="setMapMode('map')">
            <span>{{ $t('mapButton') }}</span>
          </button>
        </nav>
        <!-- TBD: necessary? -->
        <!-- <div class="show-hide-button hide-button">
          <span>{{ $t('hideMapButton') }}</span>
          <img src="/icons/chevron-up.svg" alt="Chevron" width="18" height="18" />
        </div> -->
      </template>

      <!-- Show map button -->
      <!-- Button is a div because the toggleCollapse method ignores button elements -->
      <div v-else class="show-hide-button show-button">
        <img src="/icons/map.svg" alt="Map" width="18" height="18" />
        <span>{{ $t('showMapButton') }}</span>
      </div>
    </div>

    <!-- Map -->
    <keep-alive>
      <PortfolioMap
        v-if="mapMode === 'map' && !isCollapsed"
        :buildings="buildings"
        :portfolio="portfolio"
        map-mode="map"
        :map-center="mapCenter"
        :map-zoom="mapZoom"
        :first-load="firstLoad"
        @onMapCenterUpdated="onMapCenterUpdated"
        @onMapZoomUpdated="onMapZoomUpdated"
      />
    </keep-alive>
    <keep-alive>
      <PortfolioMap
        v-if="mapMode === 'satellite' && !isCollapsed"
        :buildings="buildings"
        :portfolio="portfolio"
        map-mode="satellite"
        :map-center="mapCenter"
        :map-zoom="mapZoom"
        :first-load="firstLoad"
        @onMapCenterUpdated="onMapCenterUpdated"
        @onMapZoomUpdated="onMapZoomUpdated"
      />
    </keep-alive>
  </div>
</template>

<script>
import PortfolioMap from '@/components/portfolio/PortfolioMap.vue'

export default {
  components: {
    PortfolioMap,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      mapCenter: { lng: 8.15, lat: 46.85 },
      mapZoom: 6.5,
      firstLoad: true,
      isCollapsed: false,
    }
  },

  computed: {
    mapMode() {
      return this.$store.state.ui.portfolioMapMode
    },
  },

  created() {
    // Initialize map vs satellite
    const mapMode = localStorage.getItem('portfolioMapMode')
    if (mapMode) {
      this.$store.dispatch('ui/setPortfolioMapMode', mapMode)
    }
  },

  methods: {
    //
    setMapMode(mode) {
      this.$store.dispatch('ui/setPortfolioMapMode', mode)
      localStorage.setItem('portfolioMapMode', mode)
    },

    // Collapse / expand map
    toggleCollapse(event) {
      if (!(event.target.tagName === 'BUTTON' || event.target.closest('button'))) {
        this.isCollapsed = !this.isCollapsed
      }
    },

    //
    onMapCenterUpdated(center) {
      this.$nextTick(() => {
        this.mapCenter = center
        this.firstLoad = false
      })
      this.firstLoad = false
    },

    //
    onMapZoomUpdated(zoom) {
      this.$nextTick(() => {
        this.mapZoom = zoom
        this.firstLoad = false
      })
      this.firstLoad = false
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-map-container {
  position: relative;
  max-height: 540px;

  &.collapsed {
    max-height: 65px;
  }

  & .map-popup {
    min-width: 400px;
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 12px;
    background-color: #fff;
    border-bottom: var(--box-border);
    height: 65px;

    &:hover {
      cursor: pointer;
    }

    & .show-hide-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-s);
      flex: 1;
    }

    & .show-button {
      padding: 12px;
    }

    & .hide-button {
      text-align: right;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      & .hide-button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
