<i18n>
{
  "de": {
    "total": "Total: {count}",
    "totalFiltered": "Gezeigt: {filtered} von {total}",
    "totalFilterable": "Gezeigt: {count}",
    "totalFilterableFiltered": "Gezeigt: {filtered} von {total}",
    "planner": "Planer: {count}",
    "plannerFiltered": "Planer: {filtered} von {total}",
    "reporter": "Reporter: {count}",
    "reporterFiltered": "Reporter: {filtered} von {total}",
    "archived": "Archiviert: {count}",
    "archivedFiltered": "Archiviert: {filtered} von {total}"
  }
}
</i18n>

<template>
  <InfoBox :title="countText(filterable ? 'totalFilterable' : 'total', summaryData.total || 0)">
    <div>{{ countText('planner', summaryData.status_counts?.planner || 0) }}</div>
    <div>{{ countText('reporter', summaryData.status_counts?.reporter || 0) }}</div>
    <div>{{ countText('archived', summaryData.status_counts?.archived || 0) }}</div>
  </InfoBox>
</template>

<script>
import _ from 'lodash'

import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    InfoBox,
  },

  props: {
    summaryData: {
      type: Object,
      required: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isFiltered() {
      const totalCount = this.summaryData.total
      return _.isArray(totalCount) && totalCount[0] !== totalCount[1]
    },
  },

  methods: {
    countText(field, value) {
      if (_.isArray(value)) {
        return this.isFiltered && value[0] !== value[1]
          ? this.$t(`${field}Filtered`, { filtered: value[0], total: value[1] })
          : this.$t(field, { count: value[0] })
      } else {
        return this.$t(field, { count: value })
      }
    },
  },
}
</script>
